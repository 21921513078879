/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 620px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHlHbMTgH//xAAcEAACAgIDAAAAAAAAAAAAAAABAgARAwQhIiP/2gAIAQEAAQUCQUXN5aUTX5mQezjt/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAQEAAwAAAAAAAAAAAAAAAAEAESFx/9oACAEBAAY/AjBIluVns3//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhMWFxgf/aAAgBAQABPyFKdz2M8IMZL6yc1eCB8hL7H//aAAwDAQACAAMAAAAQfC//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxAr/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EAxjb//EABwQAQADAQEAAwAAAAAAAAAAAAEAESExQVGR0f/aAAgBAQABPxBWwVmsrizKImp286/MBBkHstGJo7w7GFe0r6/IjvlJ/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"wedding ring finger\"\n        title=\"wedding ring finger\"\n        src=\"/static/50134979ef30189a612af409583b85e7/e83c5/wedding.jpg\"\n        srcset=\"/static/50134979ef30189a612af409583b85e7/4d5fb/wedding.jpg 166w,\n/static/50134979ef30189a612af409583b85e7/558f0/wedding.jpg 333w,\n/static/50134979ef30189a612af409583b85e7/e83c5/wedding.jpg 620w\"\n        sizes=\"(max-width: 620px) 100vw, 620px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "A “ring” finger can be found on both hands of a woman so why is it that the left hand should bear the wedding ring?"), "\n", React.createElement(_components.p, null, "According to the Romans, there is a vein called the “vena amoris” that leads directly to the heart and therefore was deemed the most appropriate to wear a symbol of love."), "\n", React.createElement(_components.p, null, "Additionally, you may notice that in a wedding ceremony the bride always stands on the left."), "\n", React.createElement(_components.p, null, "The reason goes back to when men wore swords and had to defend their property and apparently their bride’s from potential intruding suitors who may wish to steal away her."), "\n", React.createElement(_components.p, null, "Most men are right handed though the sword’s scabbard would be worn on the left hip a right handed man would have to “cross draw” to have the sword in his right hand and pointed toward potential marauders hence he stands at the ready on the right side."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
